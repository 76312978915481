<template>
  <div>
    <mdb-spinner small color="blue" v-if="isloading===true" />
    <mdb-tbl v-if="isloading === false && isloadingdomains === false && tests.length > 0" hover>
      <mdb-tbl-head>
        <tr>
          <th style="font-weight: bold">ID</th>
          <th style="font-weight: bold">Name</th>
          <th class="text-center" style="font-weight: bold">Language</th>
          <th class="text-center"  style="font-weight: bold">Permalink</th>
          <th class="text-center" style="font-weight: bold">Visibility</th>
          <th class="text-center" style="font-weight: bold">Question Order</th>
          <th class="text-center" style="font-weight: bold">Action</th>
        </tr>
      </mdb-tbl-head>
      <mdb-tbl-body>
        <tr
          v-for="test in tests"
          :key="test.testid"
          style="cursor: pointer"
        >
          <td>{{ test.testid }}</td>
          <td>{{ test.name }}</td>
          <td class="text-center">{{ test.language }}</td>
          <td class="text-center" >
            <a class="btn btn-secondary" target="blank" :href="`/tests/${test.permalink}/attempt`">
              <mdb-icon icon="external-link-alt" />
            </a>
          </td>
          <td class="text-center">{{ test.open }}</td>
          <td class="text-center">{{ test.sorting }}</td>
          <td class="text-center" >
            <mdb-btn color="primary" v-on:click="$router.push(`/admin/tests/edit/${test.testid}`)">
              <mdb-icon icon="edit" />
            </mdb-btn>
          </td>
        </tr>
      </mdb-tbl-body>
    </mdb-tbl>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbIcon,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'TestTable',

  components: {
    mdbBtn,
    mdbIcon,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  data() {
    return {
      tests: [],
      domains: [],
      isloading: true,
      isloadingdomains: false,
      intro: '',
    };
  },

  mounted() {
    this.getLatestTests();
  },

  methods: {
    getLatestTests() {
      const api = new Apicall();

      api.call('GET', 'api/admintest/tests', null).then((response) => {
          this.tests = response.data.testinfos;
          this.isloading = false;
      });
    },
  },
};
</script>

<style>
</style>
