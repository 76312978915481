<template>
    <mdb-modal id="textileEditorModal"  tabindex="-1" v-bind:show="show" v-on:close="close"
        labelledby="textileEditorModalLabel">
        <mdb-modal-header id="textileEditorModalLabel">{{title}}</mdb-modal-header>
        <mdb-modal-body>
            <mdb-row>
                <mdb-col>
                    <label for="">Preview:</label>
                    <div v-if="loadingTextile" class="spinner-border text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </mdb-col>
            </mdb-row>
            <mdb-row>
                <mdb-col v-html="htmlValue"></mdb-col>
            </mdb-row>
            <mdb-textarea v-on:input="inputTextileToHtml" label="Textile" rows="4" v-model="textileValue"></mdb-textarea>
        </mdb-modal-body>
    </mdb-modal>
</template>

<script>
import {
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbCol,
    mdbRow,
    mdbTextarea,
} from 'mdbvue';

export default {
    components: {
        mdbModal, mdbModalHeader, mdbModalBody, mdbCol, mdbRow, mdbTextarea,
    },
    props: {
        title: {
            type: String,
            default: '',
            required: true,
        },
        value: {
            type: String,
            default: '',
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
            required: false,
        },
        provider: {
            type: Function,
            default: null,
            required: false,
        },
        previewTimeout: {
            type: Number,
            default: 500,
            required: false,
        },
    },
    data() {
        return {
            loadingTextile: false,
            timeout: undefined,
            textileValue: '',
            htmlValue: '',
            mutableValue: '',
        };
    },

    unmounted() {
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
    },

    methods: {
        inputTextileToHtml(event) {
            if (!this.textileValue) {
                this.textileValue = this.value;
            }

            if (this.timeout !== undefined) {
                window.clearTimeout(this.timeout);
                this.timeout = undefined;
            }

            if (!this.provider) {
                this.htmlValue = this.textileValue;
            } else {
                this.loadingTextile = true;

                this.timeout = window.setTimeout(() => {
                  this.provider(this.textileValue).then((response) => {
                    this.loadingTextile = false;
                    if (response.status === 'OK') {
                        this.htmlValue = response.data.html;
                    }
                    this.$emit('input', this.textileValue);
                  });
                }, this.previewTimeout);
              }
        },

        close() {
            this.textileValue = '';
            this.htmlValue = '';
            this.$emit('close');
        },
    },
};
</script>

<style>

</style>
