<template>
    <mdb-container fluid>
        <Header></Header>
        <Admintopbar></Admintopbar>
        <section class="mt-3">
            <mdb-row>
                <mdb-col xl="12" md="12" class="mb-4">
                    <mdb-card>
                        <mdb-card-header class="yellow darken-3 white-text">Edit Test</mdb-card-header>
                        <mdb-card-body v-if="isloading">
                                <mdb-spinner small color="blue"></mdb-spinner>
                        </mdb-card-body>
                        <mdb-card-body v-if="!isloading">
                            <mdb-btn color="secondary" v-on:click="$router.push('/admin/tests/')">
                                <mdb-icon icon="arrow-left" />
                            </mdb-btn>
                            <test-form v-on:success="$router.push('/admin/tests/')" v-if="test !== null" :key="loadkey" :test="test" :questionProp="questions"></test-form>
                            <h3 v-if="test === null">Test not found</h3>
                        </mdb-card-body>
                    </mdb-card>
                </mdb-col>
            </mdb-row>
        </section>
    </mdb-container>
</template>

<script>
import {
  mdbIcon,
  mdbCol,
  mdbBtn,
  mdbRow,
  mdbSpinner,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbContainer,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Header from '@/components/Header.vue';
import TestForm from '@/components/TestForm.vue';
import Admintopbar from '@/components/Admintopbar.vue';

export default {
    components: {
        Admintopbar,
        Header,
        mdbIcon,
        mdbBtn,
        mdbCol,
        mdbRow,
        mdbCard,
        mdbCardBody,
        mdbCardHeader,
        mdbContainer,
        mdbSpinner,
        TestForm,
    },

    data() {
        return {
            test: null,
            questions: [],
            isloading: true,
            loadkey: 0,
        };
    },

    mounted() {
        this.getTest(this.$route.params.id);
    },

    methods: {
        getTest(id) {
            const api = new Apicall();

            api.call('GET', `api/admintest/tests?id=${id}`).then((response) => {
                this.isloading = false;
                if (response.status === 'OK') {
                    this.test = response.data.testinfos;
                    this.questions = response.data.questioninfos;
                }
                this.loadkey = 1;
            });
        },
    },
};
</script>

<style>

</style>
