<template>
  <div
    ref="skillbox"
    style="
      display: grid;
      grid-template-columns: 33% 33% 33%;
      justify-content: space-between;
    "
    @change="change"
    :key="dummyKey"
  >
    <div v-for="(skill, index) in skills" :key="index">
      <mdb-input type="checkbox" :name="skill" :id="skill" :label="skill" />
    </div>
  </div>
</template>

<script>
import { mdbInput } from 'mdbvue';

export default {
  components: {
    mdbInput,
  },

  name: 'SkillboxInput',

  props: {
    value: {
      type: String,
      required: true,
    },

    skills: [],
  },

  data() {
    return {
      dummyKey: false,
      selectedSkills: [],
    };
  },

  updated() {
    this.checkSkills(null);
  },

  methods: {
    change(event) {
      const adding = event.target.checked;

      if (adding) {
        this.selectedSkills.push(event.target.name);
      } else {
        const index = this.selectedSkills.indexOf(event.target.name);
        if (index !== -1) {
          this.selectedSkills.splice(index, 1);
        }
      }

      this.$emit('input', this.selectedSkills.join(' '));
    },

    checkSkills(event) {
      this.selectedSkills.forEach((skill) => {
        const input = this.$refs.skillbox.querySelector(
          `#${skill}`,
        );
        if (input) {
          input.checked = true;
        }
      });
    },
  },

  watch: {
    value() {
      this.selectedSkills = this.value.split(' ');
    },
  },
};
</script>

<style>
</style>
