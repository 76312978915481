<template>
  <div>
    <mdb-select :label="label" :multiple="multiple" @change="onChange" v-model="valueArray"/>
  </div>
</template>

<script>
import { mdbSelect } from 'mdbvue';

export default {
  components: {
    mdbSelect,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    separator: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      valueArray: [],
    };
  },

  methods: {
    onChange() {
      this.$emit('input', this.valueArray
        .filter((element) => element.selected)
        .map((element) => element.value).join(this.separator));
    },
    reloadValues() {
      if (this.multiple) {
        const values = this.value ? this.value.split(this.separator) : [];
        this.valueArray = this.options.map((option) => ({
            value: option, text: option, selected: values.includes(option),
          }));
      } else {
        this.valueArray = this.options.map((option) => ({
            value: option, text: option, selected: option === this.value,
          }));
      }
    },
  },

  watch: {
    value() {
      this.reloadValues();
    },
    options() {
      this.reloadValues();
    },
  },

  mounted() {
    this.reloadValues();
  },
};
</script>

<style>

</style>
