<template>
  <div>
    <mdb-spinner small color="blue" v-if="isloading===true" />
    <search-test-form @input="handleValuesToSearch" @reset="handleReset"></search-test-form>
    <mdb-alert color="danger" v-if="error">
      {{error}}
    </mdb-alert>
    <mdb-tbl v-if="isloading === false && completedtests.length > 0" hover>
      <mdb-tbl-head>
        <tr>
          <th style="font-weight: bold">ID</th>
          <th style="font-weight: bold">Test</th>
          <th style="font-weight: bold">Email</th>
          <th class="text-center" style="font-weight: bold">Completed at</th>
        </tr>
      </mdb-tbl-head>
      <mdb-tbl-body v-if="!showFiltered">
        <tr
          v-for="test in completedtests"
          :key="`${test.test_id},${test.email},${test.added_at_hour_min}`"
          v-on:click="getCompletedTest(test.test_id, test.email, test.added_at_hour_min)"
          style="cursor: pointer"
        >
          <td>{{ test.test_id }}</td>
          <td>
            {{ test.name }}
            <div v-if="test.approved === undefined"></div>
            <div v-else>
              <mdb-badge v-if="test.approved === '1'" color="success">PASSED</mdb-badge>
              <mdb-badge v-else-if="test.approved === '0'" color="danger">FAILED</mdb-badge>
              <mdb-badge v-else color="warning">PENDING REVISION</mdb-badge>
            </div>
          </td>
          <td>{{ test.email }}</td>
          <td>{{ test.added_at_hour_min }}</td>
        </tr>
      </mdb-tbl-body>
      <mdb-tbl-body v-else-if="showFiltered">
        <tr
          v-for="test in searchVal"
          :key="`${test.test_id},${test.email},${test.added_at_hour_min}`"
          v-on:click="getCompletedTest(test.test_id, test.email, test.added_at_hour_min)"
          style="cursor: pointer"
        >
          <td>{{ test.test_id }}</td>
          <td>
            {{ test.name }}
            <div v-if="test.approved === undefined"></div>
            <div v-else>
              <mdb-badge v-if="test.approved === '1'" color="success">PASSED</mdb-badge>
              <mdb-badge v-else-if="test.approved === '0'" color="danger">FAILED</mdb-badge>
              <mdb-badge v-else color="warning">PENDING REVISION</mdb-badge>
            </div>
          </td>
          <td>{{ test.email }}</td>
          <td>{{ test.added_at_hour_min }}</td>
        </tr>
      </mdb-tbl-body>
    </mdb-tbl>
  </div>
</template>

<script>
import {
  mdbAlert,
  mdbBtn,
  mdbIcon,
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbBadge,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import SearchTestForm from '@/components/SearchTestForm.vue';

export default {
  name: 'CompletedTestTable',

  components: {
    mdbAlert,
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbBadge,
    SearchTestForm,
  },

  data() {
    return {
      completedtests: [],
      domains: [],
      isloading: true,
      isloadingdomains: true,
      textileEditorModal: true,
      intro: '',
      error: null,
      searchVal: [],
      showFiltered: false,
      showMessage: false,
    };
  },

  mounted() {
    this.getLatestCompletedTests();
  },

  methods: {
    getLatestCompletedTests() {
      const api = new Apicall();

      api.call('GET', 'api/admintest/completed').then((response) => {
        if (response.status === 'OK') {
          this.completedtests = response.data.completedtestinfos;
          this.isloading = false;
        } else {
          this.error = response.msg;
        }
      });
    },

    getCompletedTest(testid, email, addedathourmin) {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('testid', testid);
      urlSearchParams.append('email', email);
      urlSearchParams.append('addedathourmin', addedathourmin);
      this.$router.push(`/admin/tests/completed?${urlSearchParams}`);
    },
    handleValuesToSearch(values) {
      this.isloading = true;
      const paramsToEval = Object.entries(values).filter((element) => element[1] !== null && element[1] !== undefined && element[1].length > 0);
      const params2 = Object.fromEntries(paramsToEval);
      if (Object.keys(params2).length <= 0) {
        this.isloading = false;
        return;
      }
      const api = new Apicall();

      api.call('POST', 'api/admintest/search-tests', { searchParams: params2 }).then((response) => {
        if (response.status === 'OK') {
          if (response.data.completedtestinfos !== null) {
            this.searchVal = response.data.completedtestinfos;
            this.showFiltered = true;
            this.showMessage = false;
            this.isloading = false;
          } else {
            this.searchVal = [];
            this.showMessage = true;
            this.showFiltered = false;
            this.isloading = false;
          }
        } else {
          this.error = response.msg;
        }
      });
    },
    handleReset() {
      this.searchVal = [];
      this.showFiltered = false;
      this.showMessage = false;
    },
  },
};
</script>

<style>
</style>
