<template>
    <div>
        <form action="" v-on:submit="submitForm" v-on:input="hasError=false">
            <mdb-alert color="danger" v-if="hasError">
              {{error}}
            </mdb-alert>
            <mdb-row>
                <mdb-col sm="12" md="6" lg="5">
                    <h3>Test details</h3>
                    <div class="mt-3">
                        <mdb-input label="Name" type="text" v-model="name" v-on:input="inputPermalink" required></mdb-input>
                    </div>
                    <div class="mt-3">
                        <mdb-input label="Permalink" type="text" :value="permalink" v-on:input="inputPermalink" required></mdb-input>
                    </div>
                    <div class="mt-3" v-if="skills">
                      <multiple-answer-input v-if="editMode && skillsAreLoaded" :label="'Skills to earn'" :options="skills.map((skill) => skill.code)" v-model="test.gives_skills" :separator="' '" />
                      <multiple-answer-input v-else-if="skillsAreLoaded" :label="'Skills to earn'" :options="skills.map((skill) => skill.code)" v-model="givesSkills" :separator="' '" />
                      <label for="" v-else>Loading skills</label>
                    </div>
                    <div class="mt-3">
                        <label for="open">Visibility</label>
                        <select
                          class="browser-default custom-select"
                          id="open"
                          v-model="open"
                          required
                        >
                            <option value="OPEN">Open</option>
                            <option value="LOGGEDIN">Logged In</option>
                        </select>
                    </div>
                    <div class="mt-3">
                        <label for="soring">Sorting</label>
                        <select
                          class="browser-default custom-select"
                          id="sorting"
                          v-model="sorting"
                          required
                        >
                            <option value="ORDERED">Ordered</option>
                            <option value="RANDOM">Random</option>
                        </select>
                    </div>
                </mdb-col>
                <mdb-col>
                    <h3>Questions</h3>
                    <div class="d-flex justify-content-between align-items-baseline">
                    <select
                      class="browser-default custom-select"
                      v-model="questionType"
                    >
                      <option value="SHORTTEXT">Short Text</option>
                      <option value="LONGTEXT">Long Text</option>
                      <option value="ABCD">ABCD</option>
                      <option value="MULTIPLE-ANSWER">Multiple Answer</option>
                    </select>
                    <mdb-btn color="success" v-on:click="addQuestion"
                    ><mdb-icon icon="plus"
                    /></mdb-btn>
                </div>
                <div class="max-scroll-60vh">
                    <div v-for="(question, index) in questions" :key="index">
                      <hr>
                      <div class="d-flex align-items-baseline justify-content-between">
                        <h5>Question {{ index + 1 }} ({{ question.type }})</h5>
                        <mdb-btn v-on:click="deleteQuestion" color="danger"><mdb-icon icon="times" /></mdb-btn>
                      </div>
                      <div>
                        <select v-model="question.type" class="browser-default custom-select">
                          <option value="SHORTTEXT">Short Text</option>
                          <option value="LONGTEXT">Long Text</option>
                          <option value="ABCD">ABCD</option>
                          <option value="MULTIPLE-ANSWER">Multiple Answer</option>
                        </select>
                      </div>
                      <div>
                        <label :for="`preview-${index}`">Preview text:</label>
                        <div
                            class="border border-light py-2 px-3"
                            :id="`preview-${index}`"
                            v-html="question.html"
                        ></div>
                        <mdb-btn v-on:click="showModal(index)">Edit text</mdb-btn>
                      </div>
                      <div v-if="question.type === 'ABCD'">
                        <h5>Options:</h5>
                        <abcd-question-editor
                        v-model="question.placeholder"
                        ></abcd-question-editor>
                      </div>
                      <div v-if="question.type === 'MULTIPLE-ANSWER'">
                        <h5>Options:</h5>
                        <abcd-question-editor :multiple="true"
                        v-model="question.placeholder"
                        @input="redoMultipleAnswerArray(index)"
                        ></abcd-question-editor>
                      </div>
                      <mdb-input v-if="question.type === 'SHORT-TEXT'" type="text" label="Correct Answer" v-model="question.correct_answer" />
                      <multiple-answer-input v-else-if="question.type === 'ABCD'" :label="'Correct Answer'" :multiple="false" :separator="''" v-model="question.correct_answer" :options="question.placeholder.split('\n')" />
                      <multiple-answer-input v-else-if="question.type === 'MULTIPLE-ANSWER'" :label="'Correct Answers'" :separator="','" v-model="question.correct_answer" :options="question.placeholder.split('\n')" />
                      <mdb-input v-else
                        :cols="45"
                        :rows="15"
                        type="textarea"
                        label="Correct Answer"
                        v-model="question.correct_answer"
                      />
                    </div>
                </div>
                </mdb-col>
            </mdb-row>
            <mdb-row class="mt-1">
                <mdb-col>
                    <div class="text-center" >
                        <h3 for="intro">Test Introduction</h3>
                        <div    v-if="test ? test.intro : introHtml"
                                class="border border-light p-2 text-justify max-scroll-60vh"
                                id="intro"
                                v-html="introHtml"
                            ></div>
                        <mdb-btn v-on:click="showModal(-1)">Edit introduction</mdb-btn>
                    </div>
                </mdb-col>
            </mdb-row>
            <hr>
            <mdb-row>
                <mdb-col class="d-flex justify-content-end">
                    <mdb-btn type="submit"><mdb-icon icon="save" /> <span>Save test</span></mdb-btn>
                </mdb-col>
            </mdb-row>
        </form>
        <textile-editor
          :title="modalTitle"
          v-model="textGeneral"
          :indexElement="indexElement"
          :show="textileEditorModal"
          v-on:close="textileEditorModal = false"
          :provider="textileProvider"
          :previewTimeout="500"
        ></textile-editor>
    </div>
</template>

<script>
import TextileEditor from '@/components/TextileEditor.vue';
import SkillboxInput from '@/components/SkillboxInput.vue';
import AbcdQuestionEditor from '@/components/AbcdQuestionEditor.vue';
import {
  mdbAlert,
  mdbBtn,
  mdbCol,
  mdbIcon,
  mdbInput,
  mdbRow,
  mdbSelect,
} from 'mdbvue';
import MultipleAnswerInput from '@/components/MultipleAnswerInput.vue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    AbcdQuestionEditor,
    mdbAlert,
    mdbBtn,
    mdbCol,
    mdbRow,
    mdbIcon,
    mdbInput,
    MultipleAnswerInput,
    TextileEditor,
  },

  props: {
      test: {
          default: null,
          required: false,
      },
      questionProp: {
          default: null,
          required: false,
      },
  },

  data() {
    return {
      name: !this.test ? '' : this.test.name,
      open: !this.test ? 'OPEN' : this.test.open,
      permalink: !this.test ? '' : this.test.permalink,
      language: !this.test ? 'fr' : this.test.language,
      questions: !this.test ? [] : this.questionProp,
      sorting: !this.test ? 'ORDERED' : this.test.sorting,
      intro: !this.test || !this.test.intro ? '' : this.test.intro,
      questionType: '',
      skills: [],
      editMode: false,
      textileEditorModal: false,
      show: false,
      textGeneral: '',
      modalTitle: '',
      indexElement: null,
      hasError: false,
      error: null,
      introHtml: null,
      givesSkills: '',
      skillAreLoaded: false,
      skillObject: {},
    };
  },

  mounted() {
    this.getAllSkills();

    if (this.questionProp) {
      this.editMode = true;
      this.introHtml = this.test.html;
      this.questions = this.questionProp;
    }
  },

  methods: {
    redoMultipleAnswerArray(index) {
      if (this.questions[index].type === 'MULTIPLE-ANSWER') {
          this.questions[index].correct_answer_array = [];

          if (!this.questions[index].correct_answer) {
            this.questions[index].correct_answer = '';
          }
          const correctAnswers = this.questions[index].correct_answer.split(',');
          if (!this.questions[index].placeholder) {
            this.questions[index].placeholder = '';
          }
          this.questions[index].placeholder.split('\n').forEach((element) => {
            this.questions[index].correct_answer_array.push({
              value: element,
              text: element,
              selected: correctAnswers.indexOf(element) !== -1,
            });
          });
      }
    },
    textileProvider(textileValue) {
      const api = new Apicall();
      return api.call('POST', 'api/admintest/textile', { textile: textileValue });
    },

    parseSkillsIntoObject(skills) {
      skills.split(' ').forEach((skillCode) => {
        this.skillObject[skillCode] = true;
      });
    },

    getAllSkills() {
      if (this.test !== null) {
        this.parseSkillsIntoObject(this.test.gives_skills);
      }
      const api = new Apicall();

      api.call('GET', 'api/admintaskconfig/skills-list', null).then((response) => {
        if (response.status === 'OK') {
          this.skills = response.data;
          this.skillsAreLoaded = true;
        }
      });
    },

    change(event) {
      this.skillObject[event.target.name] = event.target.checked;
    },

    submitForm(event) {
      event.preventDefault();
      this.saveTest();
    },

    addQuestion() {
      if (!this.questionType) {
        this.error = 'Invalid question type';
        this.hasError = true;
        return;
      }

      this.questions.push({
        questionid: null,
        type: this.questionType,
        text: '',
        placeholder: '',
        textGeneral: this.questionType === 'ABCD' ? '' : null,
        ordersort: this.questions.length + 1,
        correct_answer: '',
      });
    },

    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },

    showModal(index) {
      this.indexElement = index;

      if (index !== -1) {
        this.textGeneral = this.questions[index].text;
        this.modalTitle = 'Introduction';
      } else {
        this.textGeneral = this.intro;
        this.modalTitle = `Question ${index + 1}`;
      }
      this.textileEditorModal = true;
    },

    inputPermalink(value) {
      this.permalink = value.toLowerCase().replaceAll(/[^\da-z]/g, '_');
    },

    saveTest() {
        const api = new Apicall();
        const test = {
            testid: null,
            name: this.name,
            open: this.open,
            permalink: this.permalink,
            language: this.language,
            sorting: this.sorting,
            intro: this.intro,
            gives_skills: this.test ? this.test.gives_skills : this.givesSkills,
        };

        for (let i = 0; i < this.questions.length; i += 1) {
          delete this.questions[i].html;
        }

        if (this.test !== null) {
            test.testid = this.test.testid;
            api.call('POST', 'api/admintest/edit', { ...test, questions: this.questions }).then((response) => {
                if (response.status === 'OK') {
                    this.$emit('success', response.data.test);
                } else {
                  this.error = response.msg;
                  this.hasError = true;
                }
            });
        } else {
            api.call('POST', 'api/admintest/new', { ...test, questions: this.questions }).then((response) => {
                if (response.status === 'OK') {
                    this.$emit('success', response.data.test);
                } else {
                  this.error = response.msg;
                  this.hasError = true;
                }
            });
        }
    },
  },

  watch: {
    textGeneral() {
      if (this.indexElement === null) {
        return;
      }
      const api = new Apicall();

      if (this.indexElement === -1) {
        this.intro = this.textGeneral;
        api.call('POST', 'api/admintest/textile', { textile: this.intro }).then((response) => {
            if (response.status === 'OK') {
              this.introHtml = response.data.html;
            }
        });
      } else {
        this.questions[this.indexElement].text = this.textGeneral;
        const currentQuestion = this.questions[this.indexElement];

        api.call('POST', 'api/admintest/textile', { textile: currentQuestion.text }).then((response) => {
            if (response.status === 'OK') {
              currentQuestion.html = response.data.html;
            }
        });
      }
    },
  },
};
</script>

<style scoped>
.max-scroll-60vh {
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
