<template>
    <mdb-container>
      <mdb-btn
        color="success"
        @click="showForm = !showForm"
        >
        <mdb-icon v-if="!showForm" icon="search" /> <mdb-icon v-else icon="times" /> <b>{{ !showForm ? 'Search': 'Close Form'}}</b>
      </mdb-btn>
      <div v-if="showForm" style="margin-top: 5%; margin-bottom: 5%">
      <form @submit="emitValues">
        <mdb-row>
          <mdb-col>
            <mdb-row>
              <mdb-col>
                <mdb-input v-model="from" type="date" label="From" /><br />
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <mdb-input v-model="to" type="date" label="To" />
              </mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col>
            <mdb-row>
              <mdb-col>
                <mdb-input type="email" v-model="email" label="Email"/>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <label for="testsNames"> Test Name</label>
                <select
                class="browser-default custom-select"
                id="testsNames"
                v-model="name"
                >
                <option v-for="test in tests" :key="test.id" :v-bind="test.name" style="font-size: 22px;">{{test.name}}</option>
                </select>
              </mdb-col>
            </mdb-row>
            <mdb-btn type="submit" color="success" style="margin-top: 8%;">Search</mdb-btn>
            <mdb-btn @click="reset()" color="danger" style="margin-top: 8%;">Reset</mdb-btn>
          </mdb-col>
        </mdb-row>
      </form>
    </div>
    </mdb-container>
  </template>

  <script>
  import {
      mdbCol,
      mdbInput,
      mdbRow,
      mdbBtn,
      mdbContainer,
      mdbIcon,
  } from 'mdbvue';
  import Apicall from '@/libs/Apicall';

  export default {
    components: {
          mdbCol,
          mdbInput,
          mdbRow,
          mdbContainer,
          mdbBtn,
          mdbIcon,
    },

    data() {
      return {
        from: null,
        to: null,
        email: null,
        name: null,
        showForm: false,
        tests: [],
      };
     },

     emits: ['submit'],

     methods: {
      emitValues(event) {
        event.preventDefault();
        const filters = {
          from: this.from,
          to: this.to,
          email: this.email,
          name: this.name,
        };
        this.$emit('input', filters);
      },
      getTests() {
        const api = new Apicall();
        api.call('GET', 'api/admintest/tests', null).then((response) => {
            this.tests = response.data.testinfos.sort((a, b) => a.name.localeCompare(b.name));
            this.isloading = false;
        });
      },
      reset() {
        this.from = null;
        this.to = null;
        this.email = null;
        this.name = null;
        this.$emit('reset');
      },
     },
     mounted() {
      this.getTests();
     },
  };
  </script>

  <style>
  </style>
