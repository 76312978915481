<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar :breadcrumb="breadcrumb" />
    <section class="mt-3">
      <mdb-row>
        <mdb-col xl="6" md="12" sm="12" class="mb-4">
          <mdb-card>
            <mdb-card-header class="yellow darken-3 white-text">Latest Tests</mdb-card-header>
            <mdb-card-body>
              <mdb-btn
                color="success"
                v-on:click="$router.push('/admin/tests/new');"
                >
                <mdb-icon icon="plus" /> <b>New test</b>
              </mdb-btn>
              <test-table></test-table>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col>
          <mdb-card>
            <mdb-card-header class="yellow darken-3 white-text">Latest Completed Tests</mdb-card-header>
            <mdb-card-body>
              <completed-test-table></completed-test-table>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
import TestTable from '@/components/TestTable.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import Header from '@/components/Header.vue';
import {
  mdbBtn,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbCol,
  mdbContainer,
  mdbRow,
  mdbIcon,
} from 'mdbvue';
import CompletedTestTable from '@/components/CompletedTestTable.vue';

export default {
   components: {
        Admintopbar,
        Header,
        mdbBtn,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbCol,
        mdbContainer,
        mdbIcon,
        mdbRow,
        CompletedTestTable,
        TestTable,
   },

   data() {
    return {
      error: '',
      dummy: '',
      breadcrumb: [
        {
          title: 'Tests',
          href: '/admin/tests',
        },
        {
          title: 'List',
          href: null,
        },
      ],
    };
  },
};
</script>

<style>

</style>
