<template>
    <mdb-row>
        <mdb-col>
            <label for="">Preview</label>
            <ol v-if="!multiple" class="visualizer">
                <li class="text-wrap" v-for="(line, index) in lines" :key="index">{{line}}</li>
            </ol>
            <ul v-else>
              <li class="text-wrap" v-for="(line, index) in lines" :key="index">{{line}}</li>
            </ul>
        </mdb-col>
        <mdb-col>
            <mdb-input aria-label="Placeholder" outline type="textarea" v-model="valueInternal" v-on:input="placeholderToValues" />
        </mdb-col>
    </mdb-row>
</template>

<script>
import { mdbRow, mdbCol, mdbInput } from 'mdbvue';

export default {
    components: {
        mdbRow,
        mdbCol,
        mdbInput,
    },

    props: {
        value: {
            required: true,
        },

        multiple: {
          type: Boolean,
          default: false,
        },

        label: {
            type: String,
            default: 'Options:',
            required: false,
        },
    },

    mounted() {
        this.placeholderToValues();
    },

    data() {
        return {
            lines: [],
            valueInternal: this.value,
        };
    },

    methods: {
        placeholderToValues() {
            if (this.valueInternal) {
                this.lines = this.valueInternal.split(/\r?\n/);
                this.$emit('input', this.valueInternal);
            }
        },
    },
};
</script>

<style scoped>
ol {
  list-style-type: upper-latin;
}
</style>
