<template>
  <mdb-container fluid>
    <Header></Header>
    <Admintopbar></Admintopbar>
    <section class="mt-3">
      <mdb-row>
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-card>
            <mdb-card-header class="yellow darken-3 white-text">New Test</mdb-card-header>
            <mdb-card-body>
              <mdb-btn color="secondary" v-on:click="$router.push('/admin/tests/')">
                <mdb-icon icon="arrow-left" />
              </mdb-btn>
              <test-form v-on:success="$router.push('/admin/tests/')" ></test-form>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
import Header from '@/components/Header.vue';
import TestForm from '@/components/TestForm.vue';
import Admintopbar from '@/components/Admintopbar.vue';
import {
    mdbBtn,
    mdbCol,
    mdbIcon,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbContainer,
} from 'mdbvue';

export default {
  components: {
    Admintopbar,
    Header,
    mdbBtn,
    mdbRow,
    mdbIcon,
    mdbCol,
    TestForm,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbContainer,
  },
};
</script>

<style>
</style>
